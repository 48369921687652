import React from "react"
import styled from "styled-components"
import Footer from "../../components/footer"
import Helmet from "react-helmet"

import { Row, Col } from "antd"

import { FaWhatsapp } from "react-icons/fa"

export default class Internship extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Estágio em Software</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Venha aprimorar suas habilidades de programação e usar as últimas tecnologias do mercado! Torne-se um desenvolvedor full-stack com o nosso programa de estágio"
          />
        </Helmet>

        <Header>
          <Row style={{ height: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-start",
                paddingLeft: "24px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Righteous",
                  fontSize: "1.8em",
                  width: "64px",
                  margin: "0px",
                }}
              >
                deli
              </p>
            </Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "24px",
              }}
            >
              <FaWhatsapp size="1.78em" />
              <span
                style={{
                  fontFamily: "IBM Plex Sans",
                  color: "#3f6aea",
                  fontSize: "1.2em",
                  marginLeft: "6px",
                }}
              >
                {"(41) 9 9757-4479"}
              </span>
            </Col>
          </Row>
        </Header>
        <TextSection>
          <Center>
            <Valign>
              <TextContainer>
                <h3
                  style={{ fontFamily: "IBM Plex Sans", marginBottom: "24px" }}
                >
                  ESTÁGIO EM DESENVOLVIMENTO WEB EM CURITIBA
                </h3>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                  }}
                >
                  Sobre nós:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Para você que está começando: te damos a oportunidade de
                  utilizar as melhores tecnologias do mercado e vivenciar um
                  ambiente verdadeiramente ágil. Nos orgulhamos de utilizar um
                  stack composto por ReactJS, Elixir/Phoenix e outras
                  ferramentas fundamentais ao desenvolvimento de software em
                  2020.
                </p>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Sobre esta vaga:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Esta vaga é ideal para os entusiastas ou estudantes de
                  programação que têm gana de expandir seus conhecimentos. A
                  posição contempla desenvolvimento full-stack, ou seja, tanto
                  front-end quanto back-end. Nossas equipes vão trazer mentoria
                  e direcionamento durante as atividades.
                </p>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Responsabilidades:
                </h4>
                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Melhorar a documentação de nossa infraestrutura
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Criar componentes responsivos com ReactJS
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Aprender TDD e escrever testes automatizados em Elixir
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Auxiliar a equipe de QA a encontrar e resolver bugs nas
                    interfaces
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Participar dos deploys
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Desenvolver módulos funcionais usando Elixir/OTP{" "}
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Seguir as orientações dos mentores
                  </li>
                </ul>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Aquilo que procuramos:
                </h4>

                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Conhecimento básico em ciência/engenharia da computação
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Conhecimento de GIT
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Familiaridade com Javascript
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Alguma participação em projetos Open Source
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Algum projeto que mostra que você tem garra
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Soft skills
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Inglês intermediário
                  </li>
                </ul>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Tem interesse?
                </h4>

                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Se você se interessou, envie seu currículo para
                  <b style={{ color: "#3f6aea" }}> vagas@delipos.com.br</b>
                </p>
              </TextContainer>
            </Valign>
          </Center>
        </TextSection>
        <Footer />
      </div>
    )
  }
}

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  color: #3f6aea;
  height: 48px;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

const TextSection = styled.div`
  padding-top: 48px;
  width: 100%;
  min-height: 95vh;
  background: white;
`

const TextContainer = styled.div`
  display: inline-block;
  @media (min-width: 992px) {
    width: 600px;
  }

  @media (max-width: 993px) {
    width: 90%;
  }
`

const Center = styled.div`
  display: table;
  width: 100%;
  height: auto;
`
const Valign = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`
